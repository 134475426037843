import React, { useRef, useState, useEffect } from 'react'
import api from '../api'

// const alertMsg = `Uploading new firmware to the controllers can course permanent damage
// and is entirely the users own responsibility.
// 
// Its recommended first testing new firmware on a single controller
// before updating multiple units.`;

const styleForm = {
	margin: '2em 4em 1em 2em',
}

function OptionsList(props) {
	const l = []
	for (let item of props.items) {
		l.push(<option key={item} value={item}>{item}</option>)
	}
	return (<>
		{l}
	</>)
}

const update = async (device, firmware) => {
	const param = {
		query: `
			mutation {
				device_update(device: ${device}, firmware: "${firmware}")
			}`
	}
	return api(param)
		.then(rs => rs.device_update)
}

export default function(props) {
	const [files, setFiles] = useState([''])
	const [updateState, setUpdateState] = useState('')
	const webSocket = useRef(null);

	useEffect(() => {
		const domain = window.location.href.split('/')[2]
		const usingHttps = window.location.href.split(':')[0] === 'https' ? true : false
		webSocket.current = new WebSocket(`ws${usingHttps ? 's' : ''}://${domain}/subscribe?device=${props.sn}`)
		webSocket.current.onmessage = rawMsg => {
			const stat = JSON.parse(rawMsg.data)
			if (stat.status === 'in progress') {
				const prog = stat.progress ? `: ${stat.progress}%`: ''
				setUpdateState(`updating${prog} (signal strength: ${stat.signalStrength})`)
			} else if (stat.status === 'update failed') {
				setUpdateState(`update failed`)
			} else if (stat.status === 'success') {
				setUpdateState(`update successfull, new version: '${stat.version}'`)
			}
		}
		return () => webSocket.current.close()
	}, [])

	useEffect(() => {
		props.items.then(e => setFiles(e))
	})

	useEffect(() => {
		if (props.swVersion === '')
			setUpdateState('current version unknown')
		else
			setUpdateState(`current version: ${props.swVersion}`)
	}, [props.swVersion])

	const submitName = e => {
		e.preventDefault()
		setUpdateState('updating firmware ...')
		update(props.sn, e.target.firmwareName.value)
	}

	return (
		<form style={styleForm} onSubmit={submitName}>
			<label style={{marginBottom: '10px'}}>Firmware:</label>
			<span style={{float: 'right'}}>{updateState}</span>
			<select style={{marginBottom: '10px', width: '100%'}} name="firmwareName">
				<OptionsList items={files} />
			</select>
			<input style={{marginBottom: '10px'}} type="submit" value="Update" />
		</form>
	)
}
