import React from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import api from '../api'
import DevicePage from '../devicePage/index'
import PowerGraph from '../devicePage/graph/Power'
import WaterGraph from '../devicePage/graph/Water'
import TopUsage from './TopUsage'

const style = {
	graph: {
		padding: '10px',
		width: '45%',
		minWidth: '450px',
	},
	graphs: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	header: {
		paddingLeft: '25px',
		marginTop: '30px',
	},
	table: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
	},
	entry: {
		border: '2px solid #111',
		padding: '20px',
		margin: '10px',
		backgroundColor: '#ddd',
		color: '#111',
		width: '140px',
		textAlign: 'center',
		textDecoration: 'underline #ddd',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',

		head: {
			fontWeight: 'bold',
			fontSize: '130%',
		},
		body: {
			fontSize: '80%',
			textDecoration: 'underline',
		},
	},
}

const sortAlphabetically = (a, b) => {
	if (!isNaN(a.name) && !isNaN(b.name)) {
		if (Number(a.name) < Number(b.name)) return -1 // nameA comes first
		if (Number(a.name) > Number(b.name)) return 1 // nameB comes first
		return 0 // names must be equal
	}

	return ('' + a.name.toUpperCase()).localeCompare(b.name.toUpperCase())
}

const deviceTable = (devices, farrow_section) =>
	devices.map((e, idx) =>
		(
			<Link key={idx} to={`/farrow_section_${farrow_section}/${e.id}`}>
			<div style={style.entry}>
				<div style={style.entry.head}>
					{e.name || '\u00A0'}
				</div>
				<div style={style.entry.body}>
					#{e.id}
				</div>
			</div>
			</Link>
		)
	)

function deviceRoutes(project, devices, farrow_section) {
	if (devices.length > 0) {
		return devices.map((e, idx) => {
			const path = `/farrow_section_${farrow_section}/${e.id}`
			const render = _ => <DevicePage project={project} deviceId={e.id} />
			return <Route key={idx} path={path} component={render}></Route>
		})
	} else {
		return null
	}
}

export default class extends React.Component {
	state = {}

	async componentDidMount() {
		const param = {
			query: `{
				project(uname: "${this.props.project}") {
					farrow_section_by_id(id: ${this.props.farrow_section}) {
						daily_usage
						max_usage
						id
						name
						device {
							id
							name
						}
					}
				}}`
		}
		const rs = await api(param)
		this.setState(rs.project)
	}

	render() {
		const days = [...Array(30).keys()].reverse().map(e => {
			const dates = new Date(Date.now() - (e * 24 * 60 * 60 * 1000))
			const month = String(dates.getMonth() + 1).padStart(2, '0')
			const date = String(dates.getDate()).padStart(2, '0')
			return `${month}/${date}`
		})

		const fs_info = this.state.farrow_section_by_id
		if (!fs_info) return null
		let dailyPower = { labels: days, values: Array(30) }
		if (fs_info.daily_usage) {
			const data = {
				values: JSON.parse(fs_info.daily_usage).map(e => (e.power / 1000).toFixed(2)),
				labels: JSON.parse(fs_info.daily_usage).map(e => e.date.split('-')[1] + '/' + e.date.split('-')[2]),
			}
			for (const [i, d] of data.labels.entries()) {
				const idx = dailyPower.labels.findIndex(e => e === d)
				dailyPower.values[idx] = data.values[i]
			}
		}

		let dailyWater = {labels: days, values: Array(30)}
		if (fs_info.daily_usage) {
			const data = {
				values: JSON.parse(fs_info.daily_usage).map(e => (e.water / 10).toFixed(1)),
				labels: JSON.parse(fs_info.daily_usage).map(e => e.date.split('-')[1] + '/' + e.date.split('-')[2]),
			}
			for (const [i, d] of data.labels.entries()) {
				const idx = dailyWater.labels.findIndex(e => e === d)
				dailyWater.values[idx] = data.values[i]
			}
		}

		let maxPower = []
		if (fs_info.max_usage) {
			maxPower = JSON
				.parse(fs_info.max_usage)
				.sort((a, b) => b.power - a.power)
				.map(e => ({
					id: e.id,
					name: e.name,
					value: (e.power / 1000).toFixed(2),
					unit: 'kWh',
				}))
		}

		let maxWater = []
		if (fs_info.max_usage) {
			maxWater = JSON
				.parse(fs_info.max_usage)
				.sort((a, b) => b.water - a.water)
				.map(e => ({
					id: e.id,
					name: e.name,
					value: (e.water / 10).toFixed(1),
					unit: 'liter',
				}))
		}

		if (!this.state.farrow_section_by_id) {
			return null
		}

		const devices = this.state.farrow_section_by_id.device
		devices.sort(sortAlphabetically)
		const list = _ => (
			<>
				<h2 style={{textDecoration: 'underline'}}>{this.props.farrow_section_name}</h2>
				<div style={style.graphs}>
					<div style={style.graph}>
						<h4>Power usage past month:</h4>
						{fs_info.daily_usage ? <PowerGraph labels={dailyPower.labels} values={dailyPower.values} /> : null}
						{fs_info.max_usage ? <TopUsage headline="Highest Power Consumer Today:" device={maxPower} /> : null}
					</div>
					<div style={style.graph}>
						<h4>Water usage past month:</h4>
						{fs_info.daily_usage ? <WaterGraph labels={dailyWater.labels} values={dailyWater.values} /> : null}
						{fs_info.max_usage ? <TopUsage headline="Highest Water Consumer Today:" device={maxWater} /> : null}
					</div>
				</div>
				<h2 style={style.header}>Devices:</h2>
				<div style={style.table}>
					{deviceTable(devices, this.props.farrow_section)}
				</div>
			</>
		)

		const path = `/farrow_section_${this.props.farrow_section}`
		return (
			<>
				<Switch>
					<Route exact path={path} component={list} />
					{deviceRoutes(this.props.project, devices, this.props.farrow_section)}
				</Switch>
			</>
		)
	}
}
