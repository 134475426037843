import React from 'react'
import './index.css'
import {Redirect} from 'react-router-dom'
import UserContext from '../context.js'
import api from '../api.js'
import ChangeName from './ChangeName.js'
import AccountList from './AccountList/index.js'
import AccountAdd from './AccountAdd.js'
import SetPassword from './SetPassword.js'

const styleAdmin = {
	marginLeft: '2px',
	backgroundColor: 'LimeGreen',
	fontSize: 10,
}

const styleButtons = {
	width: '200px',
	margin: '10px',
}

const styleMargin = {
	marginTop: '30px',
}

const getUsers = p => {
	const param = {
		query: `{
			project(uname: "${p}") {
				accounts {
					email
					admin
				}
			}}`
	}
	return api(param)
	.then(data => {
		return data
	})
}

/*const projectDrop = async project => {
	const param = {
		query: `
			mutation {
				Project_drop(uname: "${project}")
			}`
	}
	await api(param)
}*/

const deleteAllData = async project => {
	const param = {
		query: `
			mutation {
				project_data_delete(project: "${project}") {
					uname
				}
			}`
	}
	await api(param)
}

export default class Settings extends React.Component {
	static contextType = UserContext
	state = {
		accounts: [],
	}

	constructor() {
		super()
		this.accountUpdate = this.accountUpdate.bind(this)
	}

	componentDidMount() {
		const uname = this.context.project
		if (!uname)
			return
		getUsers(uname).then(rs => {
			this.setState({accounts: rs.project.accounts})
		})
	}

	accountUpdate(accounts) {
		this.setState({accounts})
	}

	render() {
		if (!this.context.project)
			return <Redirect to='/projects' />

		const ct = new Date(Number(this.props.creation_time))
		const ctString = `${ct.getFullYear()}/${String(ct.getMonth() + 1).padStart(2, '0')}/${ct.getDate()} ${ct.getHours()}:${ct.getMinutes()}`
		const admin = _ => this.context.admin ? <span style={styleAdmin}>Administrator</span> : null
		const dad = _ => deleteAllData(this.context.project)

		return (
			<div className="horizontalCenter">
				<div className="veticalCenter">
					<center>
						<h1>Settings</h1>
					</center>
					{/*<img src="./avatar.svg" alt="User icon" width="200px" />*/}
					<p>Your Email:&emsp;{this.context.email}{admin()}</p>
					<div style={{borderBottom: '1px solid #111'}} />
					{/*<p>Change email</p>
					<p> - send confirmation email to address and change email in system when confirmed</p>*/}
					<SetPassword />
					<div style={{borderBottom: '1px solid #111'}} />
					<AccountAdd projectName={this.props.projectName} onSubmit={a => this.setState({accounts: a})} />
					<AccountList project={this.context.project} accounts={this.state.accounts} onAccountUpdate={this.accountUpdate} />
					<div style={{borderBottom: '1px solid #111'}} />
					<ChangeName />
					<div style={{borderBottom: '1px solid #111'}} />
					<p>Project Creation Time:&emsp;{ctString}</p>
					<div style={{borderBottom: '1px solid #111'}} />
					{this.context.admin ? <input type="button" onClick={dad} value="Delete All Data" style={{...styleButtons, ...styleMargin}} /> : null}
				</div>
			</div>
		)
	}
}
