import React, {useState} from 'react'
import api from '../api'

const style = {
	setName: {
		margin: '2em 4em 1em 2em',
	}
}

const postName = async (p, g, d, name) => {
	const param = {
		query: `
			mutation {
				device_set_name(project: "${p}", gateway: ${g}, device: ${d}, name: "${name}") {
					name
				}}`
	}
	const rs = await api(param)
	return rs.device_set_name.name
}

export default function(props) {
	const [name, setName] = useState(props.name)

	const onChange = e => {
		e.preventDefault()
		setName(e.target.value)
	}

	const submitName = async e => {
		e.preventDefault()
		const p = props.project
		const d = props.deviceID
		const g = props.gatewayID
		const newName = await postName(p, g, d, name)
		props.nameSet(newName)
	}

	return (
		<div style={style.setName}>
			<form id="set-name" onSubmit={submitName}>
				<label style={{marginBottom: '10px', marginRight: '100%'}}>Set name of device:</label>
				<input style={{marginBottom: '10px'}} type="text" name="name" value={name} onChange={onChange} />
				<input style={{marginBottom: '10px', float: 'right'}} type="submit" name="" value="Submit" />
			</form>
		</div>
	)
}
