const template = document.createElement('template')
template.innerHTML = `
<style>
	div {
		margin: .5em 1em .5em 1em;
		width: 30em;
		padding-left: 1em;
		display: flex;
		flex-direction: row;
		border: 3px solid black;
	}

	div > * {
		margin-right: 1em;
	}

	div:last-child {
		margin-right: 0;
	}
</style>
<link rel="stylesheet" href="/modules/project-entry/stylesheet.css">
<div>
	<p id="gatewayId"></p>
	<p id="firstRecorded"></p>
</div>
`

class Component extends HTMLElement {
	async connectedCallback() {
		const shadow = this.attachShadow({mode: 'open'})
		shadow.appendChild(template.content.cloneNode(true))

		const dateObj = new Date(Number(this.getAttribute('firstRecorded')))
		const year = dateObj.getFullYear()
		const month = dateObj.getMonth() + 1
		const date = dateObj.getDate()
		const hour = dateObj.getHours()
		const minute = dateObj.getMinutes()
		const second = dateObj.getSeconds()
		shadow.querySelector('#firstRecorded').innerText = `${year}/${month}/${date} ${hour}:${minute}:${second}`
		shadow.querySelector('#gatewayId').innerText = `#${this.getAttribute('gatewayId')}`
	}
}

customElements.define('new-gateway-entry', Component)
