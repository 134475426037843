import React, { Component } from 'react'
import Chart from 'chart.js'

export default class TempWaterGraph extends Component {
	chartRef = React.createRef()
	state = {}

	componentDidMount() {
		const myChartRef = this.chartRef.current.getContext('2d')
		const values = this.props.values
		const c = new Chart(myChartRef, {
			type: 'line',
			data: {
				labels: values.timestamp,
				datasets: [
					{
						pointRadius: 0,
						pointHitRadius: 10,
						borderWidth: 2,
						lineTension: 0,
						yAxisID: 'temp',
						fill: false,
						borderColor: '#ff6347',
						data: values.temperature,
					}, {
						pointRadius: 0,
						pointHitRadius: 10,
						borderWidth: 2,
						lineTension: 0,
						yAxisID: 'water',
						fill: false,
						borderColor: '#4682b4',
						data: values.water,
					},
				]
			},
			options: {
				stacked: false,
				legend: {
					display: false,
				},

				scales: {
					xAxes: [{
						ticks: {
							fontColor: '#111'
						},
					}],
					yAxes: [{
						id: 'temp',
						position: 'left',
						scaleLabel: {
							display: true,
							fontColor: '#ff6347',
							fontStyle: 'bold',
							fontSize: 14,
							labelString: '\u2103',
						},
						ticks: {
							fontColor: '#000',
							suggestedMin: 10,
							suggestedMax: 35,
						},
					},{
						id: 'water',
						position: 'right',
						gridLines: {
							drawOnChartArea: false,
						},
						scaleLabel: {
							display: true,
							fontColor: '#4682b4',
							fontStyle: 'bold',
							fontSize: 14,
							labelString: 'Liters',
						},
						ticks: {
							fontColor: '#000',
							suggestedMin: 0,
							suggestedMax: 1,
						},
					}]
				}
			}
		})

		this.setState({chart: c})
	}

	render() {
		const styleDiv = {
			backgroundColor: '#f8f8f8',
			border: '0.1em solid black',
			boxShadow: '0.2em 0.2em 0.3em',
			padding: '20px',
		}

		const values = this.props.values
		if (this.state.chart) {
			const chart = this.state.chart
			chart.labels = values.timestamp
			chart.data.datasets[0].data = values.temperature
			chart.data.datasets[1].data = values.water
			chart.update()
		}

		return (
			<div style={styleDiv}>
				<canvas ref={this.chartRef} />
			</div>
		)
	}
}
