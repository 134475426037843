import React from 'react'
import api from '../api.js'

export default class SetPassword extends React.Component {
	state = {
		oldPass: '',
		newPass: '',
		newPassCopy: '',
		error: '',
	}

	constructor() {
		super()
		this.onOldPassChanged = this.onOldPassChanged.bind(this)
		this.onNewPassCopyChanged = this.onNewPassCopyChanged.bind(this)
		this.onNewPassChanged = this.onNewPassChanged.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}

	onOldPassChanged = e => this.setState({oldPass: e.target.value})

	onNewPassChanged = e => this.setState({newPass: e.target.value})

	onNewPassCopyChanged = e => this.setState({newPassCopy: e.target.value})

	onSubmit = e => {
		e.preventDefault()

		if (this.state.newPass !== this.state.newPassCopy) {
			this.setState({error: '"New Password" fields have to match'})
			return
		}

		const query = `
			mutation {
				accountSetPassword(oldPassword: "${this.state.oldPass}", newPassword: "${this.state.newPass}")
			}`
		api({query})
		.then(rs => this.setState({error: rs.accountSetPassword}))
	}

	render() {
		return (
			<>
				<h3 style={{marginBottom: '0em'}}>Change Password</h3>
				<form onSubmit={this.onSubmit}>
					<h5>Current Password</h5>
					<input type="password" onChange={this.onOldPassChanged} value={this.state.oldPass} />
					<h5>New Password</h5>
					<input type="password" onChange={this.onNewPassChanged} value={this.state.newPass} />
					<h5>New Password Again</h5>
					<input type="password" onChange={this.onNewPassCopyChanged} value={this.state.newPassCopy} />
					<input type="submit" value="Submit" style={{width: '7em', margin: '10px'}} />
					<p style={{color: 'red', marginTop: '2em'}}>{this.state.error}</p>
				</form>
			</>
		)
	}
}
