import React from 'react'
import { Redirect } from 'react-router-dom'
import api from '../api.js'
import ProjectEntry from './ProjectEntry/index.js'
import UserContext from '../context.js'

export default class ProjectList extends React.Component {
	static contextType = UserContext
	state = {
		projects: [],
	}

	componentDidMount() {
		const param = {
			query: `{
				projects {
					uname
					name
					creation_time
					total_usage
					farrow_sections {
						id
					}
				}}`
		}
		api(param)
		.then(data => this.setState({projects: data.projects}))
	}

	newProject(e) {
		e.preventDefault()
		const name = e.target.newName.value
		const param = {
			query: `
				mutation {
					project_new(name: "${name}", timezone: "Europe/Copenhagen") {
						uname
						name
						creation_time
						total_usage
					}
				}`,
		}
		api(param)
		.then(data => {
			const joined = this.state.projects.concat(data.project_new);
			this.setState({projects: joined})
		})
	}

	render() {
		const setProject = uname => {
			this.context.methods.setProject(uname)
			this.setState({redirect: true})
		}
		const newProject = e => this.newProject(e)

		const styleForm = {
			margin: '40px 0 80px 0',
		}

		let rs = []
		let index = 0
		for (let project of this.state.projects) {
			index += 1
			const { total_power, total_water } = JSON.parse(project.total_usage)
			rs.push(
				<ProjectEntry
					key={index}
					style={{ margin: '.5em 1em 0.5em .5em' }}
					clickEvent={setProject}
					default_farrow_section={project.farrow_sections?.[0]?.id}
					uname={project.uname} name={project.name}
					creation_time={project.creation_time}
					power_usage={total_power}
					water_usage={total_water} />
			)
		}

		const showNewProject = this.context.admin ?
			(<form style={styleForm} onSubmit={newProject}>
				<h2>New Project</h2>
				<input type="text" name="newName" />
				<input type="submit" />
			</form>) : null

		return (
			<>
				{this.state.redirect ? <Redirect to="device" /> : null}
				<div className="horizontalCenter">
					<div className="veticalCenter">
						{showNewProject}
						<h2>Projects</h2>
						{rs}
					</div>
				</div>
			</>
		)
	}
}
