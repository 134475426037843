import React, { useState, useEffect } from 'react'
import './index.css'
import SetName from './SetName'
import Dropdown from './dropdown.js'
import api from '../api'
import UserContext from '../context.js'

const style = {
	infoBox: {
		display: 'flex',
		flexDirection: 'row',
	},
	icon: {
		width: '30px',
		margin: '25px 10px 10px 10px',
	},
	gatewayId: {
		marginLeft: '5px',
		fontSize: 10,
	}
}

const firmware_files = async () => {
	const param = {
		query: `{
			firmware_files
		}`
	}
	const rs = await api(param)
	return rs.firmware_files
}

function NoData(props) {
	const [name, setName] = useState('')

	useEffect(() => {
		setName(props.name);
	}, [props.name])

	const rootAndFirmware = value => value.admin ? <Dropdown items={firmware_files()} swVersion={props.swVersion} sn={props.deviceId} /> : null

	return (
		<div className="wrapper-center">
			<div className="wrapper-grid">
				<div className="control-header">
					<h1>{name || '#' + props.deviceId}&nbsp;<span style={style.gatewayId}>Gateway ID: {props.gatewayID}</span></h1>
					<div>
						{/*<img src="../log.svg" style={style.icon} alt="controller data log" />
						<img src="../settings.svg" style={style.icon} alt="controller settings" />*/}
					</div>
				</div>
				<div style={style.infoBox}>
					<div>
						<div className="latest">
							<h3 style={{marginTop: '0'}}>Latest Data</h3>
							<div>... No data present</div>
						</div>
					</div>
					<div>
						<SetName project={props.project} gatewayID={props.gatewayID} deviceID={props.deviceId} name={name || ''} nameSet={setName} />
						<UserContext.Consumer>
							{rootAndFirmware}
						</UserContext.Consumer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NoData
