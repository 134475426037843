import React from 'react'
import './pena-farmdata-logo.js'

function NotFound(props) {
	return (
		<>
			<a id="logolink" href="/" style={{textDecoration: 'none'}}>
				<pena-farmdata-logo></pena-farmdata-logo>
			</a>
			<center>
				<h2 style={{marginTop: '5em'}}>This Page Does Not Exist</h2>
			</center>
		</>
	)
}

export default NotFound
