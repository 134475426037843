import React from 'react'
import UserContext from '../context.js'
import api from '../api.js'

const changeName = async (uname, name) => {
	const param = {
		query: `
			mutation {
				project_name_change(project: "${uname}", name: "${name}") {
					name
				}
			}`
	}
	const rs = await api(param)
	return rs.project_name_change.name
}

const styleForm = {
	marginBottom: '30px',
}

export default class ChangeName extends React.Component {
	static contextType = UserContext

	constructor() {
		super()
		this.changeName = this.changeName.bind(this)
	}

	async changeName(e) {
		e.preventDefault()
		const t = e.target
		const newName = t.name.value
		if (window.confirm(`Are you sure you want to change the name of the project to '${newName}'`)) {
			t.name.value = await changeName(this.context.project, newName)
			window.location.reload()
		}
	}

	render() {
		return (
			<form style={styleForm} onSubmit={this.changeName}>
				<h3>
					Change project name:
				</h3>
				<input type='text' name='name' defaultValue={this.context.project.name}/>
			</form>
		)
	}
}
