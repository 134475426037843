export default function(obj) {
	obj.query = obj.query.replace(/\t/g, '')
	return fetch('/api', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(obj)
	})
	.then(rs => {
		if (rs.status === 401) {
			if (window.location.pathname !== '/login') {
				window.location.replace('/login')
			}
			return Promise.reject()
		}
		return rs.json()
	})
	.then(rs => {
		if ('errors' in rs) {
			console.error(rs.errors[0].message)
			return Promise.reject(rs.errors[0].message)
		}
		return rs.data
	})
}
