const template = document.createElement('template')
template.innerHTML = `
<style>
	img {
		margin: 2em;
		width: 8em;
	}
</style>
<img src="./pena-logo.svg" alt="Pena Farmdata">
`

class PenaFarmdataLogo extends HTMLElement {
	constructor() {
		super()

		this.attachShadow({mode: 'open'})
		this.shadowRoot.appendChild(template.content.cloneNode(true))
	}

	connectedCallback() {
	}
}

customElements.define('pena-farmdata-logo', PenaFarmdataLogo)
