import React from 'react'
import {Redirect} from 'react-router-dom'

class Verify extends React.Component {
	constructor() {
		super()
		this.onPassChanged = this.onPassChanged.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
		this.state = {
			email: '',
			key: window.location.search.split('=')[1],
			redirect: false,
		}
	}

	componentWillMount() {
		fetch(`/verify?key=${this.state.key}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
		}).then(rs => {
			if (rs.ok) {
				rs.json().then(obj => this.setState({email: obj.email}))
			}
		})
	}

	onPassChanged = e => {
		const password = e.target.value
		this.setState({password})
	}

	onSubmit = async e => {
		e.preventDefault();
		const rs = await fetch('/verify', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: this.state.email,
				password: this.state.password,
				key: this.state.key,
			})
		})
		if (rs.ok) {
			this.setState({redirect: true})
		} else {
			this.setState({redirect: true})
		}
	}

	render() {
		const styleImg = {
			margin: '1em',
			width: '100px',
		}

		return (
			<>
				{this.state.redirect ? <Redirect to="/" /> : null}
				<img src="./penando-logo.png" style={styleImg} alt="PENA Logo" />
				<h1>Confirm your account on Pena Farmdata</h1>
				<h2>Set new password</h2>
				<form onSubmit={this.onSubmit}>
					<h3 style={{marginBottom: '2px', marginTop: '8px'}}>User: {this.state.email}</h3>
					<h3 style={{marginBottom: '2px', marginTop: '8px'}}>Password:</h3>
					<input type="password" name="password" required autoComplete="on" autoFocus="autofocus" onChange={this.onPassChanged} value={this.state.password} />
					<input type="submit" value="Submit" />
				</form>
			</>
		)
	}
}


export default Verify
