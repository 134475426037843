import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const styleLink = {
	color: 'inherit',
	textDecoration: 'none',
}

export default class Entry extends React.Component {
	render() {
		const path = `/edit_farrow_sections/${this.props.sectionId}`
		return (
			<Link to={path} style={styleLink} >
				<div className="Entry">
					<p id="name">Name:&nbsp;&nbsp;
						<span style={{ fontWeight: 'bold' }}>{this.props.name}</span>
					</p>
				</div>
			</Link>
		)
	}
}
