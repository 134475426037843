export default function(e) {
	const getCookie = cname => {
		let name = cname + '='
		let ca = decodeURIComponent(document.cookie).split(';')
		for (let c of ca) {
			while (c.charAt(0) === ' ') {
				c = c.substring(1)
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length)
			}
		}
		return null
	}

	const parseJwt = token => {
		try {
			return JSON.parse(atob(token.split('.')[1]))
		} catch (e) {
			return null
		}
	}

	const cookie = getCookie('token')
	if (!cookie) {
		return null
	}

	const token = parseJwt(cookie)
	if (!token) {
		return null
	}

	const expiration = new Date(token.exp * 1000)
	if (expiration < Date.now()) {
		return null
	}

	return true
}
