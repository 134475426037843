import React from 'react'
import UserContext from '../context.js'
import api from '../api.js'

const accountNew = async (uname, email) => {
	const param = {
		query: `
			mutation {
				account_assign(project: "${uname}", email: "${email}") {
					accounts {
						email
						admin
					}
				}
			}`
	}
	const rs = await api(param)
	return rs.account_assign.accounts
}

export default class AccountAdd extends React.Component {
	static contextType = UserContext

	constructor() {
		super()
		this.accountAdd = this.accountAdd.bind(this)
	}

	async accountAdd(e) {
		e.preventDefault()
		const t = e.target
		const email = t.email.value
		if (email === '')
			return
		const project = this.props.projectName
		if (window.confirm(`Are you sure you want to add user '${email}' to project '${project}'`)) {
			const uname = this.context.project
			const accounts = await accountNew(uname, email)
			if (this.props.onSubmit)
				this.props.onSubmit(accounts)
			t.email.value = ''
		}
	}

	render() {
		return (
			<form onSubmit={this.accountAdd}>
				<h3>
					Add new user by email:
				</h3>
				<input type='email' name='email' />
				<input type="submit" value="Submit" style={{width: '7em', margin: '10px'}} />
			</form>
		)
	}
}
