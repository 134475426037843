import React, { useState, useEffect } from 'react'
import api from '../../api.js'
import { useParams } from 'react-router-dom'

const styleP = {
	lineHeight: '3em',
	width: '175px',
}

const styleSpan = {
	fontWeight: 'bold',
}

const styleBut = {
	marginRight: '10px',
}

const styleTable = {
	borderCollapse: 'collapse',
}

const styleHeader = {
	borderBottom: '1px solid black',
}

const styleRow = {
	borderBottom: '1px solid lightgrey',
}


function Device(props) {
	function assign() {
		if (props.assigned) {
			// deassign device
			api({
				query: `mutation {
					farrow_section_remove_device(sectionId: ${props.fs}, deviceId: ${props.id}) {
						id
					}
				}`
			})
		} else {
			// assign device
			api({
				query: `mutation {
					farrow_section_add_device(sectionId: ${props.fs}, deviceId: ${props.id}) {
						id
					}
				}`
			})
		}
		props.f()
	}

	function remove() {
		api({
			query: `mutation {
				device_remove_unassigned(device: ${props.id})
			}`
		})
		props.f()
	}

	function actionButt() {
		if (props.assigned)
			return <button onClick={assign}>unassign</button>
		else
			return <>
				<button onClick={assign} style={styleBut}>assign</button>
				<button onClick={remove}>delete</button>
			</>
	}

	return (
		<tr style={styleRow}>
			<td style={styleP}>
				Gateway:&nbsp;&nbsp;
				<span style={styleSpan}>{props.gateway}</span>
			</td>
			<td style={styleP}>
				ID:&nbsp;&nbsp;
				<span style={styleSpan}>{props.id}</span>
			</td>
			<td style={styleP}>
				Name:&nbsp;&nbsp;
				<span style={styleSpan}>{props.name}</span>
			</td>
			<td style={styleP}>
				SW:&nbsp;&nbsp;
				<span style={styleSpan}>{props.sw_version}</span>
			</td>
			<td style={styleP}>
				{actionButt()}
			</td>
		</tr>
	)
}

export default function(props) {
	const [unAssigned, setUnAssigned] = useState([])
	const [assigned, setAssigned] = useState([])
	const id = Number(useParams().fsid)

	function update() {
		api({
			query: `{
				devices(project: "${props.project}") {
					id
					name
					farrow_section {
						id
						name
					}
					gateway {
						id
					}
					sw_version
					first_recorded_time
				}
			}`
		}).then(rs => {
			setUnAssigned(rs.devices.filter(e => !e.farrow_section))
			setAssigned(rs.devices.filter(e => e.farrow_section?.id === id))
		})
	}

	useEffect(() => {
		update()
	}, [id])

	const styleForm = {
		margin: '40px 0 80px 0',
	}

	let assigned_el = []
	let unAssigned_el = []
	let index = 0
	for (let section of assigned) {
		index += 1
		assigned_el.push(
			<Device key={index} project={props.project} id={section.id} name={section.name} gateway={section.gateway.id} sw_version={section.sw_version} fs={id} assigned={true} f={update} />
		)
	}

	index = 0
	for (let section of unAssigned) {
		index += 1
		unAssigned_el.push(
			<Device key={index} project={props.project} id={section.id} name={section.name} gateway={section.gateway.id} sw_version={section.sw_version} fs={id} assigned={false} f={update} />
		)
	}

	return (
		<>
			<div className="horizontalCenter">
				<div className="veticalCenter">
					<h2 style={{margin: '50px auto 30px auto'}}>Assigned Devices</h2>
					<table style={styleTable}>
						<thead>
							<tr style={styleHeader}>
								<th>Gateway</th>
								<th>ID</th>
								<th>Name</th>
								<th>SW</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{assigned_el}
						</tbody>
					</table>
					<h2 style={{margin: '50px auto 30px auto'}}>Unassigned Devices</h2>
					<table style={styleTable}>
						<thead>
							<tr style={styleHeader}>
								<th>Gateway</th>
								<th>ID</th>
								<th>Name</th>
								<th>SW</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{unAssigned_el}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
