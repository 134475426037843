import React from 'react'

const style = {
	main: {
		margin: '20px',
	},
	name: {
		fontWeight: 'bold',
	},
	id: {
		fontSize: '80%',
	},
	value: {
		display: 'inline-block',
		width: '6em',
		textAlign: 'right',
	},
}

export default function(props) {
	if (!props.device)
		return null

	if (props.device.length === 0)
		return null

	const lst = props.device.map((e, idx) => {
		return (
			<div key={idx}>
				<span style={style.name}>{e.name}&nbsp;</span>
				<span style={style.id}>{e.id}</span>:
				<span style={style.value}>&nbsp;{e.value} {e.unit}</span>
			</div>
		)
	})

	return (
		<div style={style.main}>
			<h4>{props.headline}</h4>
			{lst}
		</div>
	)
}
