import React from 'react'

const styleDiv = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
}

const styleButton = {
	height: '25px',
	width: '90px',
	marginRight: '20px',
}

const styleEmpty = {
	width: '90px',
	marginRight: '20px',
}

export default class Account extends React.Component {
	render() {
		return (
			<div style={styleDiv}>
				{!this.props.admin ?
					<button style={styleButton} onClick={_ => this.props.onDelete(this.props.email)}>delete</button> :
					<div style={styleEmpty}></div>}
				<p>{this.props.email}</p>
			</div>
		)
	}
}
