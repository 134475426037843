import React from 'react'
import api from '../api'
import WaterGraph from './graph/Water'
import PowerGraph from './graph/Power'
import TempWaterGraph from './graph/TempWater'
import './index.css'
import NoData from './NoData'
import SetName from './SetName'
import LimitWater from './LimitWater.js'
import StartButton from './HeatingProfile/Start'
import ProfileButtons from './HeatingProfile/ProfileButtons'
import Dropdown from './dropdown.js'
import UserContext from '../context.js'

const firmware_files = async () => {
	const param = {
		query: `{
			firmware_files
		}`
	}
	const rs = await api(param)
	return rs.firmware_files
}

const info = async (uname, number) => {
	const param = {
		query: `{
			project(uname: "${uname}") {
				device(id: ${number}) {
					gateway {
						id
					}
					farrow_section {
						name
					}
					name
					sw_version
					first_recorded_time
					latest
					last_day_data
					daily_usage
					profile
					onOff
					onTime
					limits
				}}}`
	}
	const rs = await api(param)
	return rs.project.device
}

const getLatest = async (uname, number) => {
	const query = `{
		project(uname: "${uname}") {
			device(id: ${number}) {
				profile
			}}}`
	const rs = await api({query})
	return rs.project.device
}

const style = {
	infoBox: {
		display: 'flex',
		flexDirection: 'row',
	},
	setName: {
		margin: '2em 4em 1em 2em',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
	},
	subHeader: {
		marginTop: '20px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	runningTime: {
		display: 'none',
	},
	gatewayId: {
		marginLeft: '5px',
		fontSize: 10,
	},
}

export default class DevicePage extends React.Component {
	state = {
		data: [],
		date: new Date(),
	}

	async componentDidMount() {
		const p = this.props.project
		const id = this.props.deviceId
		const rs = await info(p, id)
		if (!rs.latest) {
			this.setState({
				gatewayID: rs.gateway.id,
				farrowSection: rs.farrow_section.name,
				profile: rs.profile,
				onOff: rs.onOff,
				name: rs.name,
				swVersion: rs.sw_version,
			})
			return
		}

		const latest = JSON.parse(rs.latest)
		this.setState({
			latest: {
				time: new Date(latest.time).valueOf(),
				value: latest.value,
			},
			gatewayID: rs.gateway.id,
			farrowSection: rs.farrow_section.name,
			profile: rs.profile,
			onOff: rs.onOff,
			name: rs.name,
			swVersion: rs.sw_version,
			onTime: rs.onTime,
			lastDayData: JSON.parse(rs.last_day_data),
			dailyUsage: JSON.parse(rs.daily_usage),
			limits: JSON.parse(rs.limits),
		})

		console.log(this.state.limits)

//		const domain = window.location.href.split('/')[2]
//		const usingHttps = window.location.href.split(':')[0] === 'https' ? true : false
//		const ws = new WebSocket(`ws${usingHttps ? 's' : ''}://${domain}/subscribe?device=${id}`)
//		ws.onmessage = function(rawMsg) {
//			console.log(JSON.parse(rawMsg.data))
//		}
	}

	render() {
		const valueWriter = _ => {
			if (!this.state.latest)
				return null

			const t = new Date(this.state.latest.time)
			const year = t.getFullYear()
			const month = String(t.getMonth() + 1).padStart(2, '0')
			const date = String(t.getDate()).padStart(2, '0')
			const hour = String(t.getHours()).padStart(2, '0')
			const minute = String(t.getMinutes()).padStart(2, '0')
			//const second = String(t.getSeconds()).padStart(2, '0')
			const timeStr = `${year}/${month}/${date} ${hour}:${minute}`

			const kwh = (this.state.latest.value[1] / 1000).toFixed(2)
			const liters = (this.state.latest.value[2] / 10).toFixed(1)
			return (
				<>
					<p>Farrow Section: {this.state.farrowSection}</p>
					<p>Local Time: {timeStr}</p>
					<p>Temperature: {this.state.latest.value[0]}&#8451;</p>
					<p>Power Consumption: {kwh} kWh</p>
					<p>Water Consumption: {liters} liters</p>
				</>
			)
		}

		const waterTempData = _ => {
			if (!this.state.lastDayData.length)
				return null

			const tsformat = ts => {
				const hour = String(ts.getHours()).padStart(2, '0')
				const min = String(ts.getMinutes()).padStart(2, '0')
				//const sec = String(ts.getSeconds()).padStart(2, '0')
				return `${hour}:${min}`
			}

			return {
				timestamp: this.state.lastDayData.map(e => tsformat(new Date(e.time))),
				temperature: this.state.lastDayData.map(e => e.value[0]),
				//power: this.state.lastDayData.map(e => e.value[1] / 1000),
				water: this.state.lastDayData.map(e => (e.value[2] / 10).toFixed(1)),
			}
		}

		const drawGraphs = _ => {
			const days = [...Array(30).keys()].reverse().map(e => {
				const dates = new Date(Date.now() - (e * 24 * 60 * 60 * 1000))
				const month = dates.getMonth()
				const date = dates.getDate()
				return {month, date}
			})

			const daysForm = days.map(e => {
				const m = String(e.month + 1).padStart(2, '0')
				const d = String(e.date).padStart(2, '0')
				return `${m}/${d}`
			})

			const powerData = days.map(e => {
				const data = this.state.dailyUsage.map(d => ({
					date: {
						month: Number((new Date(d.date)).getMonth()),
						date: Number((new Date(d.date)).getDate()),
					},
					power: d.daily_power,
				}))
				const p = data.find(d => d.date.month === e.month && d.date.date === e.date)
				if (p)
					return p.power / 1000
				return null
			})

			const waterData = days.map(e => {
				const data = this.state.dailyUsage.map(d => ({
					date: {
						month: Number((new Date(d.date)).getMonth()),
						date: Number((new Date(d.date)).getDate()),
					},
					water: d.daily_water,
				}))
				const p = data.find(d => d.date.month === e.month && d.date.date === e.date)
				return p ? (p.water / 10).toFixed(1) : null
			})

			const wtValues = waterTempData()

			return (
				<>
					<div className="graph">
						<div style={{display: 'flex', flexDirection: 'row',}}>
							<h3 style={{marginRight: '30px'}}>Temperature & Water</h3>
							{/*<Selector onChange={newDate} />*/}
						</div>
						{wtValues ? <TempWaterGraph values={wtValues} /> : null}
					</div>
					<div className="graph">
						<h3>kWh per Day</h3>
						<PowerGraph labels={daysForm} values={powerData} />
					</div>
					<div className="graph">
						<h3>Liters per Day</h3>
						<WaterGraph labels={daysForm} values={waterData} />
					</div>
				</>
			)
		}

		if (!this.state.latest)
			return <NoData project={this.props.project} gatewayID={this.state.gatewayID} deviceId={this.props.deviceId} name={this.state.name} swVersion={this.state.swVersion} />

		const minutesToDhm = minutes => {
			minutes = Number(minutes)
			let d = Math.floor(minutes / (60*24))
			let h = Math.floor(minutes % (60*24) / 60)
			let m = Math.floor(minutes % 60)

			let dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : ""
			let hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : ""
			let mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes ") : ""
			return dDisplay + hDisplay + mDisplay
		}

		const profileMin = !this.state.latest ? null : minutesToDhm(this.state.latest.value[3])

		const setProfile = p => {
			if (!window.confirm('Are you sure you want to change the profile?'))
				return
			const param = {
				query: `
					mutation {
						set_profile(device: ${this.props.deviceId}, profile: ${p})
					}`,
			}
			api(param).then(rs => {
				const p = this.props.project
				const id = this.props.deviceId
				return getLatest(p, id)
			}).then(rs => this.setState({profile: rs.profile}))
		}

		const nameSet = name => this.setState({name: name})
		const rootAndFirmware = value => value.admin ? <Dropdown items={firmware_files()} swVersion={this.state.swVersion || 'unknown'} sn={this.props.deviceId} /> : null

		return (
			<div className="wrapper-center">
				<div className="wrapper-grid">
					<div className="control-header">
						<div style={style.header}>
							<h1>{this.state.name || '#' + this.props.deviceId}&nbsp;</h1>
							<div style={style.subHeader}>
								<div style={style.gatewayId}>Device ID: {this.props.deviceId}</div>
								<div style={style.gatewayId}>Gateway ID: {this.state.gatewayID}</div>
							</div>
						</div>
						<div>
							{/*<img src="../log.svg" style={styleIcon} alt="controller data log" />
							<img src="../settings.svg" style={styleIcon} alt="controller settings" />*/}
						</div>
					</div>
					<div style={style.infoBox}>
						<div>
							<div className="latest">
								<h3 style={{marginTop: '0'}}>Latest Data</h3>
								{valueWriter()}
							</div>
							<div className="profile">
								<h3>Heating Profile</h3>
								<StartButton device={this.props.deviceId} />
								<ProfileButtons onTime={this.state.onTime} onOff={this.state.onOff} profile={this.state.latest.value[4] || this.state.profile} setProfile={setProfile} />
								<p style={style.runningTime}>Has been running for {profileMin}</p>
							</div>
						</div>
						<div>
							<SetName project={this.props.project} gatewayID={this.state.gatewayID} deviceID={this.props.deviceId} name={this.state.name || ''} nameSet={nameSet} />
							<UserContext.Consumer>
								{rootAndFirmware}
							</UserContext.Consumer>
							<LimitWater deviceID={this.props.deviceId} min={this.state.limits.water_min} max={this.state.limits.water_max} />
						</div>
					</div>
					{drawGraphs()}
				</div>
			</div>
		)
	}
}
