import React from 'react'

const style = {
	marginTop: '5px',
}

function Time (props) {
	const days = Math.floor(props.value / (24 * 60))
	const hours = Math.floor(props.value % (24 * 60) / 60)

	if (props.value === 0)
		return null

	return (
		<div style={style}>
			<span>days: {days} </span>
			<span>hours: {hours}</span>
		</div>
	)
}

export default props => {
	const color = props.onOff ? '#22AB22' : 'red'
	const colorPicker = (n, profile) => ({ backgroundColor: n === profile ? color : 'white' })

	return <>
		<div className="profile-buttons">
			<div style={colorPicker(1, props.profile)} onClick={() => props.setProfile(1)}>C1</div>
			<div style={colorPicker(2, props.profile)} onClick={() => props.setProfile(2)}>C2</div>
			<div style={colorPicker(3, props.profile)} onClick={() => props.setProfile(3)}>C3</div>
		</div>
		{props.onOff ? <Time value={props.onTime} /> : null}
	</>
}
