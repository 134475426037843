import React, {createContext} from 'react'
import api from './api.js'
import checkCredentials from './checkCredentials.js'

const UserContext = createContext()

const assignDevice = async (project, device) => {
	const param = {
		query: `
			mutation {
				device_assign(project: "${project}", id: ${device}) {
					number
				}
			}`
	}
	await api(param)
	//const newDevices = await checkNewDevices()
	//const projectUpdated = await updateProject(project)
	//return {devices: newDevices, project: projectUpdated}
	return
}

let initialState = {}

async function loadData() {
	if (checkCredentials()) {
		const userData = JSON.parse(window.localStorage.getItem('userData'))
		initialState.project = userData ? userData.project : null

		const param = {
			query: `{
				account {
					email
					admin
				}}`
		}
		return await api(param).then(data => {
			initialState.email = data.account.email
			initialState.admin = data.account.admin
		})
		.catch(_ => {})
	}

//	const param = window.location.search
//	if (/^\?key=[0-9a-fA-F]{16}$/.test(param)) {
//		const key = param.split('=')[1]
//		return await fetch('/authenticate', {
//			method: 'POST',
//			headers: {
//				'Content-Type': 'application/json'
//			},
//			body: JSON.stringify({
//				key: key,
//				useCookie: true,
//			})
//		})
//		.then(rs => {
//			if (!rs.ok)
//				return Promise.reject()
//
//			return rs.json()
//		})
//		.then(rs => {
//			state = rs
//			state.project = null
//			window.localStorage.setItem('userData', JSON.stringify(state))
//			window.location.reload()
//		})
//		.catch(e => {
//			console.log('Login failed');
//		})
//	}
}

export let load = async _ => await loadData()

export class UserProvider extends React.Component {
	state = {...initialState,
		methods: {
			setProject: async project => {
				this.setState({project: project})
				const userData = {
					email: this.state.email,
					admin: this.state.admin,
					project: project,
				}
				window.localStorage.setItem('userData', JSON.stringify(userData))
			},
			assignDevice: async deviceId => {
				if (!this.project)
				return
				const pUname = this.project.uname
				const {devices, project} = await assignDevice(pUname, deviceId)
				this.setState({project: project})
				this.setState({orphanDevices: devices})
			},
		}
	}

	render() {
		return (
			<UserContext.Provider value={this.state}>
				{this.props.children}
			</UserContext.Provider>
		)
	}
}

export default UserContext
