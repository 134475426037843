import React from 'react'
import './index.css'

function RootNode(props) {
	return (
		<>
			<input type="checkbox" id="checkbox" />
			<div className="root">
				<div className="header">
					<label htmlFor="checkbox" className="menu-btn">
						<div className="menu-icon"></div>
					</label>
					{props.header}
				</div>
				<div className="menu">
					{props.menu}
				</div>
				<div className="content">
					{props.children}
				</div>
			</div>
		</>
	)
}

export default RootNode
