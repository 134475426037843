import React from 'react'

class Login extends React.Component {
	constructor() {
		super()
		this.onEmailChanged = this.onEmailChanged.bind(this)
		this.onPassChanged = this.onPassChanged.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
		this.state = {
			email: '',
			password: '',
			errorMessage: '',
		}
	}

	onEmailChanged = e => {
		const email = e.target.value
		this.setState({email})
	}

	onPassChanged = e => {
		const password = e.target.value
		this.setState({password})
	}

	onSubmit = async e => {
		e.preventDefault();
		const rs = await fetch('/authenticate', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: this.state.email,
				password: this.state.password,
				useCookie: true,
			})
		})
		if (rs.ok) {
			window.location.reload()
		} else {
			this.setState({
				email: '',
				password: '',
				errorMessage: 'Wrong credentials. Please try again',
			})
		}
	}

	render() {
		const styleInput = {
			backgroundColor: '#f8f8f8',
			border: 'solid 3px black',
			borderRadius: '30px',

			height: '28px',
			width: '15em',
			fontSize: '18pt',
			outlineWidth: '0',

			marginTop: '2mm',
			paddingLeft: '2mm',
			marginBottom: '1em',
		}

		const styleInvInput = {
			...styleInput,
			visibility: 'visible',
			height: '100%',
			width: '30mm',
		}

		const styleImg = {
			margin: '4em',
			width: '18em',
		}

		return (
			<div>
				<img src="/penando-logo.png" style={styleImg} alt="PENA Logo" />
				<center>
					<form style={{paddingTop: '9em'}} onSubmit={this.onSubmit}>
						<h3 style={{color: 'red', marginBottom: '2em'}}>{this.state.errorMessage}</h3>
						<h3 style={{marginBottom: '2px', marginTop: '8px'}}>User:</h3>
						<input type="email" name="email" required autoFocus="autofocus" style={styleInput} onChange={this.onEmailChanged} value={this.state.email} />
						<h3 style={{marginBottom: '2px', marginTop: '8px'}}>Password:</h3>
						<input type="password" name="password" required autoComplete="on" autoFocus="autofocus" style={styleInput} onChange={this.onPassChanged} value={this.state.password} />
						<br />
						<br /><input value="Login" type="submit" style={styleInvInput} />
						<input type="hidden" name="useCookie" value="true" />
					</form>
				</center>
			</div>
		)
	}
}


export default Login
