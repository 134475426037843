import React from 'react'
import './new-gateway-entry.js'

export default class NewDevices extends React.Component {
	render() {
		const assignThis = e => {
			const gatewayId = e.target.getAttribute('gatewayId')
			this.props.onAssign(gatewayId)
		}

		const unassigned = this.props.gateways
		.map((e, idx) =>
			<new-gateway-entry key={idx} style={{margin: '.5em 1em 0.5em .5em', border: '3px black'}} gatewayId={e.id} firstRecorded={e.first_recorded} onClick={assignThis} />
		)

		return (
			<div>
				{unassigned}
			</div>
		)
	}
}
