import React from 'react'
import api from '../../api'
import './Start.css'

const style = {
	border: '3px solid #111',
	margin: '0px 20px 20px 0px',
	padding: '10px 20px 10px 20px',
	width: 'min-content',
	whiteSpace: 'nowrap',
}

const startProfile = async id => {
	const param = {
		query: `mutation {
				device_start_profile(device: ${id})
			}`
	}
	await api(param)
	setTimeout(() => window.location.reload(), 1000)
}

export default props => {
	const onClick = () => startProfile(props.device)
	
	return <div className="start-profile" style={style} onClick={onClick}>Start Profile</div>
}
