import React, {useState} from 'react'
import api from '../api'

const style = {
	setName: {
		margin: '2em 4em 1em 2em',
	},
	inputs: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}

const postLimit = (d, min, max) => api({
	query: `
		mutation {
			device_set_limit_water(device: ${d}, min: ${min}, max: ${max}) {
				limits
			}}`
})

export default function(props) {
	const [limit, setLimit] = useState({
		min: props.min,
		max: props.max,
	})

	const changeMin = e => {
		e.preventDefault()
		setLimit({ min: e.target.value, max: limit.max })
	}

	const changeMax = e => {
		e.preventDefault()
		setLimit({ min: limit.min, max: e.target.value })
	}

	const submitName = async e => {
		e.preventDefault()
		const d = props.deviceID
		const min = Number(limit.min) || null
		const max = Number(limit.max) || null
		await postLimit(d, min, max)
	}

	return (
		<div style={style.setName}>
			Water consumption limit (Liter):
			<form id="set-name" onSubmit={submitName} style={style.inputs}>
				<div style={{marginRight: '50px'}}>
					<div>
						<label style={{marginBottom: '10px'}}>Max:</label>
					</div>
					<input style={{marginBottom: '10px', width: '100px'}} type="number" name="min" value={limit.min || ''} onChange={changeMin} />
				</div>
				<div style={{marginRight: '50px'}}>
					<div>
						<label style={{marginBottom: '10px'}}>Min:</label>
					</div>
					<input style={{marginBottom: '10px', width: '100px'}} type="number" name="max" value={limit.max || ''} onChange={changeMax} />
				</div>
				<input style={{marginBottom: '10px', float: 'right', height: '22px'}} type="submit" name="" value="Submit" />
			</form>
		</div>
	)
}
