import React from 'react'
import Account from './Account.js'
import api from '../../api.js'

const styleAccountList = {
	margin: '20px 0 20px 0',
}

const deleteAccount = async (project, email) => {
	const param = {
		query: `
			mutation {
				account_deassign(project: "${project}", email: "${email}") {
					accounts {
						email
						admin
					}
				}
			}`
	}
	const rs = await api(param)
	return rs.account_deassign.accounts
}

export default class AccountList extends React.Component {
	render() {
		const accountDeleted = async email => {
			if (window.confirm(`Are you sure you want to delete user '${email}' from project`)) {
				const accounts = await deleteAccount(this.props.project, email)
				this.props.onAccountUpdate(accounts)
			}
		}
		return (
			<div style={styleAccountList}>
				{this.props.accounts.map((e, idx) => <Account key={idx} email={e.email} admin={e.admin} onDelete={accountDeleted} />)}
			</div>
		)
	}
}
