import React from 'react'
import { Redirect } from 'react-router-dom'

class Logout extends React.Component {
	componentDidMount() {
		document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
		window.localStorage.clear()
		window.location.reload()
	}

	render() {
		return <Redirect to='/login' />
	}
}

export default Logout
