import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Login from './login.js'
import Logout from './logout.js'
import MainPage from './mainPage.js'
import NotFound from './notFound.js'
import UserContext from './context.js'
import Verify from './Verify.js'

class Main extends React.Component {
	render() {
		const rootOrLogin = value => value.email ? <Redirect to='/' /> : <Login />
		const mainpageOrLogin = value => {
			function Mp(props) {
				return <MainPage admin={value.admin} project={value.project} location={props.location} />
			}
			const comp = value.email ? Mp : Login
			return <Route path='/' component={comp} />
		}
		return (
			<Router>
				<Switch>
					<Route exact path='/login'>
						<UserContext.Consumer>
							{rootOrLogin}
						</UserContext.Consumer>
					</Route>
					<Route exact path='/logout' component={Logout} />
					<Route exact path='/new_user' component={Verify} />
					<UserContext.Consumer>
						{mainpageOrLogin}
					</UserContext.Consumer>
					<Route exact path='/404' component={NotFound} />
					<Redirect from='*' to='/404' />
				</Switch>
			</Router>
		)
	}
}

export default Main
