import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const styleLink = {
	color: 'inherit',
	textDecoration: 'none',
}

export default class ProjectEntry extends React.Component {
	render() {
		const dateObj = new Date(Number(this.props.creation_time))
		const year = dateObj.getFullYear()
		const month = dateObj.getMonth() + 1
		const date = dateObj.getDate()
		const dateStr = `${year}/${month}/${date}`

		const thicken = p => <span>{p}</span>
		const defaultSection = this.props.default_farrow_section
		const path = defaultSection ? `/farrow_section_${defaultSection}` : '/edit_farrow_sections'
		const power = Math.floor(this.props.power_usage / 1000)
		const water = Math.floor(this.props.water_usage / 10)
		return (
			<Link to={path} style={styleLink} >
				<div className="ProjectEntry" onClick={_ => this.props.clickEvent(this.props.uname)}>
					<div>
						<p id="name">Name:&nbsp;&nbsp;{thicken(this.props.name)}</p>
						<p id="creation-date">Created:&nbsp;&nbsp;{thicken(dateStr)}</p>
						<p id="power-usage">Power Usage (30 days):&nbsp;&nbsp;{thicken(`${power} kWh`)}</p>
						<p id="water-usage">Water Usage (30 days):&nbsp;&nbsp;{thicken(`${water} Liter`)}</p>
					</div>
				</div>
			</Link>
		)
	}
}
