import React from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import RootNode from './rootNode/index.js'
import ProjectList from './projects/index.js'
import NewGateways from './newGateways.js'
import MenuProjectName from './MenuProjectName'
import './menuLink.css'
import Settings from './settings/index'
import api from './api.js'
import Devices from './devices/index.js'
import EditFarrowSections from './editFarrowSections/index.js'
import AssignDevice from './editFarrowSections/assignDevice/index.js'

const About = () => <h1>About</h1>

const getProject = async (admin, uname) => {
	const param = {
		query: `{
			project(uname: "${uname}") {
				name
				timezone
				creation_time
				farrow_sections {
					id
					name
				}
			}
			${admin ?
				`unassigned_gateway {
					id
					first_recorded
				}` : ''
			}
		}`
	}
	return await api(param)
}

async function assignGateway(project, gatewayId) {
	const param = {
		query: `
			mutation {
				gateway_assign(project: "${project}", id: ${gatewayId}) {
					assigned
				}
			}`,
	}
	await api(param)
	window.location.reload()
	return false
}

const styleLogo = {
	width: '13em',
	margin: '2em auto 4em auto',
	display: 'block',
}

const projectName = (project, name) => {
	if (!project)
		return 'No Project'

	if (!name)
		return null

	return name
}

class MainPage extends React.Component {
	state = {
		project: {
			devices: [],
		},
		unassigned_gateway: [],
	}

	componentDidMount() {
		if (!this.props.project)
			return

		getProject(this.props.admin, this.props.project)
		.then(details => this.setState({...details}))
	}

	render() {
		const showNewDevices =
			this.state.unassigned_gateway.length > 0 ?
				<Link className='link' to="/new_gateways">New Gateways</Link> :
				null

		const path = window.location.href.replace(window.location.origin,'')
		const selectedRoute = {
			projects: /^\/projects/.test(path) ? {backgroundColor: '#aaa'} : null,
			new_gateways: /^\/new_gateways/.test(path) ? {backgroundColor: '#aaa'} : null,
			settings: /^\/settings/.test(path) ? {backgroundColor: '#aaa'} : null,
			about: /^\/about/.test(path) ? {backgroundColor: '#aaa'} : null,
			farrowsection: /^\/edit_farrow_sections/.test(path) ? {backgroundColor: '#aaa'} : null,
		}

		const projectDisplayName = projectName(this.props.project, this.state.project.name)

		let farrow_sections = null
		if (this.state.project.farrow_sections) {
			const sortAlphabetically = (a, b) => {
				if (!isNaN(a.name) && !isNaN(b.name)) {
					if (Number(a.name) < Number(b.name)) return -1 // nameA comes first
					if (Number(a.name) > Number(b.name)) return 1 // nameB comes first
					return 0 // names must be equal
				}
				return ('' + a.name.toUpperCase()).localeCompare(b.name.toUpperCase())
			}
			farrow_sections = this.state.project.farrow_sections.sort(sortAlphabetically).map((e, i) => {
				const path = `/farrow_section_${e.id}`
				return (<Link key={i} className='sub-link' to={path} style={selectedRoute.devices}>&bull;&nbsp;&nbsp;{e.name}</Link>)
			})
		}

		const menuContent = (
			<>
				<img style={styleLogo} src='/penando-logo.png' alt='logo' />
				<MenuProjectName name={projectDisplayName} />
				<div className='menu-header'>Farrow Sections:</div>
				{farrow_sections}
				<div style={{height: '2em'}}></div>
				{showNewDevices}
				<Link className='link' to='/edit_farrow_sections' style={selectedRoute.farrowsection}>Edit Farrow Sections</Link>
				<Link className='link' to='/settings' style={selectedRoute.settings}>Settings</Link>
				<Link className='link' to='/projects' style={selectedRoute.projects}>Projects</Link>
				{/*<Link className='link' to='/about' style={selectedRoute.about}>About</Link>*/}
				<Link className='link' to='/logout'>Logout</Link>
			</>
		)

		const onAssign = id => assignGateway(this.props.project, id)
		const settings = _ => <Settings projectName={this.state.project.name} creation_time={this.state.project.creation_time} />
		const newGateways = _ => <NewGateways onAssign={onAssign} gateways={this.state.unassigned_gateway} />


		let devicePages = null
		if (this.state.project.farrow_sections) {
			devicePages = this.state.project.farrow_sections.map((e, i) => {
				const path = `/farrow_section_${e.id}`
				return (
					<Route key={i} path={path}>
						<Devices farrow_section_name={e.name} farrow_section={e.id} project={this.props.project} />
					</Route>
				)
			})
		}

		return (
			<RootNode menu={menuContent}>
				<Switch>
					{devicePages}
					<Route exact path='/edit_farrow_sections'>
						<EditFarrowSections project={this.props.project} />
					</Route>
					<Route path='/edit_farrow_sections/:fsid'>
						<AssignDevice project={this.props.project} />
					</Route>
					<Route exact path='/new_gateways' component={newGateways} />
					<Route exact path='/projects' component={ProjectList} />
					<Route exact path='/settings' component={settings} />
					<Route exact path='/about' component={About} />
					{/*<Route exact path='/' component={Default} />*/}
					<Redirect exact from='/' to='/projects' />
				</Switch>
			</RootNode>
		)
	}
}

export default MainPage
