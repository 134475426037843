import React from 'react'
import api from '../api.js'
import Entry from './entry/index.js'

export default class ProjectList extends React.Component {
	state = {
		sections: [],
	}

	componentDidMount() {
		const param = {
			query: `{
				project(uname: "${this.props.project}") {
					farrow_sections {
						id
						name
					}
				}}`
		}
		api(param)
		.then(data => this.setState({ sections: data.project.farrow_sections }))
	}

	async newFarrowSection(e) {
		e.preventDefault()
		const name = e.target.newName.value
		const param = {
			query: `
				mutation {
					farrow_section_add(project: "${this.props.project}", name: "${name}") {
						id
						name
					}
				}`,
		}
		await api(param)
		window.location.reload()
		return false
	}

	render() {
		const newFarrowSection = e => this.newFarrowSection(e)

		const styleForm = {
			margin: '40px 0 80px 0',
		}

		let rs = []
		let index = 0
		for (let section of this.state.sections) {
			index += 1
			rs.push(
				<Entry key={index} style={{ margin: '.5em 1em 0.5em .5em' }} sectionId={section.id} name={section.name} />
			)
		}

		return (
			<div className="horizontalCenter">
				<div className="veticalCenter">
				<form style={styleForm} onSubmit={newFarrowSection}>
					<h2>New Farrow Section</h2>
					<input type="text" name="newName" />
					<input type="submit" value="submit" style={{ margin: '15px' }}/>
				</form>
					<h2>Farrow Sections</h2>
					{rs}
				</div>
			</div>
		)
	}
}
