import React, { Component } from 'react'
import Chart from 'chart.js'

export default class extends Component {
	chartRef = React.createRef()

	componentDidMount() {
		const myChartRef = this.chartRef.current.getContext('2d')
		//const timestamps = this.props.labels.map(e => `${e.getFullYear()}/${e.getMonth() + 1}/${e.getDate()} ${e.getHours()}:`)
		const values = this.props.values
		new Chart(myChartRef, {
			type: 'bar',
			data: {
				labels: this.props.labels,
				datasets: [
					{
						data: values,
						backgroundColor: '#ff6347',
					}
				]
			},
			options: {
				scales: {
					xAxes: [{
						ticks: {
							fontColor: '#111'
						},
					}],
					yAxes: [{
						position: 'left',
						scaleLabel: {
							display: true,
							fontColor: '#ff6347',
							fontStyle: 'bold',
							fontSize: 14,
							labelString: 'kWh',
						},
						ticks: {
							fontColor: '#111',
							suggestedMin: 0,
						},
					}],
				},
				legend: {
					display: false,
				}
			}
		})
	}

	render() {
		const styleDiv = {
			backgroundColor: '#f8f8f8',
			border: '0.1em solid black',
			boxShadow: '0.2em 0.2em 0.3em',
			padding: '20px',
		}
		return (
			<div style={styleDiv}>
				<canvas ref={this.chartRef} />
			</div>
		)
	}
}
